import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

import usersModule from './users';
import jobsModule from './jobs';
import tasksModule from './tasks';
import bubblesModule from './bubbles';
import reportsModule from './reports';
import utilitiesModule from './utilities';
import questionsModule from './questions';
import isoModule from './iso';
import loadingModule from './loading';
import documentsModule from './documents';
import diagramModule from './diagram';
import offlineModule from './offline';
import client from '../config/api';

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    UsersModule: usersModule,
    JobsModule: jobsModule,
    ReportsModule: reportsModule,
    UtilitiesModule: utilitiesModule,
    TasksModule: tasksModule,
    BubblesModule: bubblesModule,
    QuestionsModule: questionsModule,
    IsoModule: isoModule,
    LoadingModule: loadingModule,
    DocumentsModule: documentsModule,
    DiagramModule: diagramModule,
    OfflineModule: offlineModule,
  },
});

client.injectStore(store);

export default store;
